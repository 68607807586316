import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import Table from "../../components/tables/Table";
import Pagination from "../../components/pagination/Pagination";
import { AiFillHome } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import Card from "../../components/cards/Card";
import Select from "../../components/inputFields/SelectField";
import InputField from "../../components/inputFields/InputField";
import Button from "../../components/buttons/Button";
import ToggleSwitch from "../../components/ToggleSwitch";
import CustomColumnText from "../../components/CustomColumnText";
import { getDeviceDropDownApi } from "../../services/apis/commodity";
import showAlert from "../../components/errorBoundries/Errors";
import DynamicMultiLineChart from "../../components/DeviceLogs/DynamicMultiLineChart";
import StyledHeadingText from "../../components/Fonts/StyledHeadingText";
import { getDeviceLogsCardsApi, getDeviceLogsHomeApi } from "../../services/apis/device_logs";
import { generatedeviceLogExcelApiUrl, generatedeviceLogPdfApiUrl } from "../../constant/urls/apiUrls";
import RenderPythonGraph from "../../components/DeviceLogs/RenderPythoGraph";


const DeviceLogs = () => {
  const headers = [
    "Date",
    "Temperature",
    "Humidity",
    "Required Humidity",
    "Required Temperature",
    "P1 Temperature",
    "P2 Temperature",
    "P3 Temperature",
    "P4 Temperature",
    "Solar Supply",
    "R Phase",
    "Y Phase",
    "B Phase",
    "Battery Level",
    "Low Pressure",
    "High Pressure",
    "Valve 1",
    "Valve 2",
    "Valve 3",
    "Water Level",
    "Door Open",
    "Fan 1",
    "Fan 2",
    "Fan 3",
    "Fan 4",
    "Fan 5",
    "Fan 6",
    "Fan 7",
    "Message",
    "PWC",
    "SSR",
    "Relay",
    "Heater",
    "Drive",
    "Set Precool",
    "RT1",
    "RT2",
    "RT Average",
    "RRH1",
    "RRH2",
    "RRH Average",
    "AT",
    "ARH",
    "ST",
    "Solar Voltage",
    "SC",
    "SPKWH",
    "Battery Voltage",
    "Battery CC",
    "Battery DC",
    "RC",
    "YC",
    "BC",
    "Grid Power Average",
    "Grid PKWH",
    "Input High Pressure",
    "Input Low Pressure",
    "Input KP5",
    "Input INT60",
    "Input DEF",
    "Input DRF",
    "Input WL",
    "Input RD",
    "Input PD",
    "Output Spare",
    "Output DG Off",
    "Output DG On",
    "Output PCF",
    "Output SV4",
    "Output SV3",
    "Output SV2",
    "Output SV1",
    "Output Heater",
    "Output HM",
    "Output BC",
    "Output PC",
    "Output SP",
    "Output ODF4",
    "Output ODF3",
    "Output ODF2",
    "Output ODF1",
    "Output IDF4",
    "Output IDF3",
    "Output IDF2",
    "Output IDF1",
    "Output DFT R",
    "Output DAA",
  ];
  const keys = [
    "created_at",
    "temperature",
    "humidity",
    "req_humi",
    "req_temp",
    "plate_temperature_1",
    "plate_temperature_2",
    "plate_temperature_3",
    "plate_temperature_4",
    "solar_supply",
    "r_phase",
    "y_phase",
    "b_phase",
    "battery_level",
    "low_pressure",
    "high_pressure",
    "valve_1",
    "valve_2",
    "valve_3",
    "water_level",
    "door_open",
    "fan_1",
    "fan_2",
    "fan_3",
    "fan_4",
    "fan_5",
    "fan_6",
    "fan_7",
    "msg",
    "pwc",
    "ssr",
    "relay",
    "heater",
    "drive",
    "setPrecool",
    "rT1",
    "rT2",
    "rTAvg",
    "rRH1",
    "rRH2",
    "rRHAvg",
    "aT",
    "aRH",
    "sT",
    "solar_voltage",
    "sC",
    "sPKWH",
    "batteryV",
    "batteryCC",
    "batteryDC",
    "rC",
    "yC",
    "bC",
    "gridPAvg",
    "gridPKWH",
    "iHP",
    "iLP",
    "iKP5",
    "iINT60",
    "iDEF",
    "iDRF",
    "iWL",
    "iRD",
    "iPD",
    "oSpare",
    "oDGOff",
    "oDGOn",
    "oPCF",
    "oSV4",
    "oSV3",
    "oSV2",
    "oSV1",
    "oHTR",
    "oHM",
    "oBC",
    "oPC",
    "oSP",
    "oODF4",
    "oODF3",
    "oODF2",
    "oODF1",
    "oIDF4",
    "oIDF3",
    "oIDF2",
    "oIDF1",
    "oDFtR",
    "oDAA",
  ];

  const headers2 = [
    "Date",
    "Temperature",
    "Humidity",
    "Required Humidity",
    "Required Temperature",
    "P1 Temperature",
    "P2 Temperature",
    "P3 Temperature",
    "Solar Supply",
    "R Phase",
    "Y Phase",
    "B Phase",
    "Battery Level",
    "Low Pressure",
    "High Pressure",
    "Valve 1",
    "Valve 2",
    "Valve 3",
    "Water Level",
    "Door Open",
    "Fan 1",
    "Fan 2",
    "Fan 3",
    "Fan 4",
    "Fan 5",
    "Fan 6",
    "Fan 7",
    "Message",
    "PWC",
    "SSR",
    "Relay",
    "Heater",
    "Drive",
  ];
  const keys2 = [
    "created_at",
    "temperature",
    "humidity",
    "req_humi",
    "req_temp",
    "plate_temperature_1",
    "plate_temperature_2",
    "plate_temperature_3",
    "solar_supply",
    "r_phase",
    "y_phase",
    "b_phase",
    "battery_level",
    "low_pressure",
    "high_pressure",
    "valve_1",
    "valve_2",
    "valve_3",
    "water_level",
    "door_open",
    "fan_1",
    "fan_2",
    "fan_3",
    "fan_4",
    "fan_5",
    "fan_6",
    "fan_7",
    "msg",
    "pwc",
    "ssr",
    "relay",
    "heater",
    "drive",
  ];

  const [isNewData, setIsNewData] = useState(false);
  const [deviceOptions, setDeviceOptions] = useState({});
  const [deviceLogData, setDeviceLogData] = useState({});
  const [selectedDevice, setSelectedDevice] = useState("");
  const [selectedDeviceStartDate, setSelectedDeviceStartDate] = useState("");
  const [selectedDeviceEndDate, setSelectedDeviceEndDate] = useState("");
  const [graphicalMode, toggleGraphicalMode] = useState(false);
  const [isLoadingGraph, setIsLoadingGraph] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const availableIcons = [
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#1C64F2" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2 11.2002C18.3513 11.2002 17.5374 11.5373 16.9373 12.1375C16.3371 12.7376 16 13.5515 16 14.4002V33.6002C16 34.4489 16.3371 35.2628 16.9373 35.8629C17.5374 36.4631 18.3513 36.8002 19.2 36.8002H28.8C29.6487 36.8002 30.4626 36.4631 31.0627 35.8629C31.6629 35.2628 32 34.4489 32 33.6002V14.4002C32 13.5515 31.6629 12.7376 31.0627 12.1375C30.4626 11.5373 29.6487 11.2002 28.8 11.2002H19.2ZM24 33.6002C24.4243 33.6002 24.8313 33.4316 25.1314 33.1316C25.4314 32.8315 25.6 32.4245 25.6 32.0002C25.6 31.5758 25.4314 31.1689 25.1314 30.8688C24.8313 30.5688 24.4243 30.4002 24 30.4002C23.5757 30.4002 23.1687 30.5688 22.8686 30.8688C22.5686 31.1689 22.4 31.5758 22.4 32.0002C22.4 32.4245 22.5686 32.8315 22.8686 33.1316C23.1687 33.4316 23.5757 33.6002 24 33.6002V33.6002Z" fill="white" />
    </svg>,
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#1BA005" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2 11.2002C18.3513 11.2002 17.5374 11.5373 16.9373 12.1375C16.3371 12.7376 16 13.5515 16 14.4002V33.6002C16 34.4489 16.3371 35.2628 16.9373 35.8629C17.5374 36.4631 18.3513 36.8002 19.2 36.8002H28.8C29.6487 36.8002 30.4626 36.4631 31.0627 35.8629C31.6629 35.2628 32 34.4489 32 33.6002V14.4002C32 13.5515 31.6629 12.7376 31.0627 12.1375C30.4626 11.5373 29.6487 11.2002 28.8 11.2002H19.2ZM24 33.6002C24.4243 33.6002 24.8313 33.4316 25.1314 33.1316C25.4314 32.8315 25.6 32.4245 25.6 32.0002C25.6 31.5758 25.4314 31.1689 25.1314 30.8688C24.8313 30.5688 24.4243 30.4002 24 30.4002C23.5757 30.4002 23.1687 30.5688 22.8686 30.8688C22.5686 31.1689 22.4 31.5758 22.4 32.0002C22.4 32.4245 22.5686 32.8315 22.8686 33.1316C23.1687 33.4316 23.5757 33.6002 24 33.6002V33.6002Z" fill="white" />
    </svg>,
    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#CC0000" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2 11.2002C18.3513 11.2002 17.5374 11.5373 16.9373 12.1375C16.3371 12.7376 16 13.5515 16 14.4002V33.6002C16 34.4489 16.3371 35.2628 16.9373 35.8629C17.5374 36.4631 18.3513 36.8002 19.2 36.8002H28.8C29.6487 36.8002 30.4626 36.4631 31.0627 35.8629C31.6629 35.2628 32 34.4489 32 33.6002V14.4002C32 13.5515 31.6629 12.7376 31.0627 12.1375C30.4626 11.5373 29.6487 11.2002 28.8 11.2002H19.2ZM24 33.6002C24.4243 33.6002 24.8313 33.4316 25.1314 33.1316C25.4314 32.8315 25.6 32.4245 25.6 32.0002C25.6 31.5758 25.4314 31.1689 25.1314 30.8688C24.8313 30.5688 24.4243 30.4002 24 30.4002C23.5757 30.4002 23.1687 30.5688 22.8686 30.8688C22.5686 31.1689 22.4 31.5758 22.4 32.0002C22.4 32.4245 22.5686 32.8315 22.8686 33.1316C23.1687 33.4316 23.5757 33.6002 24 33.6002V33.6002Z" fill="white" />
    </svg>,
  ];
  const [cardData, setCardData] = useState([
    {
      icon: availableIcons[0],
      title: "Total Devices",
      count: "--",
    },
    {
      icon: availableIcons[1],
      title: "Active Devices",
      count: "--",
    },
    {
      icon: availableIcons[2],
      title: "Disabled Devices",
      count: "--",
    },
  ]);
  const pageSize = 100;

  const handleExcel = (rowData) => {
    if (selectedDevice !== "") {
      window.open(generatedeviceLogExcelApiUrl + selectedDevice + "&from_date=" + selectedDeviceStartDate.split("T")[0] + "& to_date=" +
        selectedDeviceEndDate.split("T")[0], "_blank");
    } else {
      showAlert("Select Device", "Please Select a device to download excel", "error");
    }
  };

  const handlePring = (rowData) => {
    if (selectedDevice !== "") {
      window.open(generatedeviceLogPdfApiUrl + selectedDevice + "&from_date=" + selectedDeviceStartDate.split("T")[0] + "& to_date=" +
        selectedDeviceEndDate.split("T")[0], "_blank");
    } else {
      showAlert("Select Device", "Please Select a device to download excel", "error");
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const respones = await getDeviceDropDownApi();
        if (respones?.status === 200) {
          setDeviceOptions(respones?.data);
        } else {
          let errors = "Opps! Something went wrong, please try again.";
          showAlert("Error", errors, "error");
        }
      } catch (error) {
        let errors =
          error?.response?.data?.detail ||
          "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const respones = await getDeviceLogsCardsApi();
        if (respones?.status === 200) {
          setCardData([
            {
              icon: availableIcons[0],
              title: "Total Devices",
              count: respones?.data.total_device,
            },
            {
              icon: availableIcons[1],
              title: "Active Devices",
              count: respones?.data.active_device,
            },
            {
              icon: availableIcons[2],
              title: "Disabled Devices",
              count: respones?.data.disabled_device,
            },
          ]);
        } else {
          let errors = "Opps! Something went wrong, please try again.";
          showAlert("Error", errors, "error");
        }
      } catch (error) {
        let errors =
          error?.response?.data?.detail ||
          "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    };
    fetchData();
  }, []);

  const transformData = () => {
    const options = [];
    if (deviceOptions && deviceOptions) {
      for (let i = 0; i < deviceOptions.length; i++) {
        const item = deviceOptions[i];
        options.push({
          value: item.id,
          label: item.device_name,
        });
      }
    }
    return options;
  };

  const getDeviceLogData = async ({ deviceId, startTime, endTime }) => {
    setSelectedDevice(deviceId);
    setSelectedDeviceStartDate(startTime);
    setSelectedDeviceEndDate(endTime);
    if (deviceId !== "") {
      try {
        const response = await getDeviceLogsHomeApi({ deviceId: deviceId, startDate: startTime.replace("T", " "), endDate: endTime.replace("T", " "), });
        if (response?.status === 200) {
          setDeviceLogData(response?.data);
          setIsNewData(response?.data?.extra_data?.json_type === "new");
        } else {
          let errors = "Opps! Something went wrong, please try again.";
          showAlert("Error", errors, "error");
        }
      } catch (e) {
        let errors =
          e?.response?.data?.detail ||
          "Opps! Something went wrong, please try again.";
        showAlert("Error", errors, "error");
      }
    }
  };
  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsLoadingGraph(true);
    setSubmitting(true);
    await getDeviceLogData({ deviceId: values.device, startTime: values.from_date, endTime: values.to_date });
    setSubmitting(false);
    setIsLoadingGraph(false);
  };

  const handleToggle = async (value) => {
    toggleGraphicalMode(value);
  };

  return (
    <div className="">
      {/* <Toast /> */}
      {/* Top Section Begin */}
      <div className="flex gap-3 items-center font-medium text-gray-500">
        <AiFillHome />
        <p>Home</p>
        <IoIosArrowForward />
        <p>Device Logs</p>
      </div>
      {/* Top Section End */}
      {/* Add Faqs Begin */}
      <div className="flex justify-between pt-3">
        <div className="w-[30%]"></div>
        <div className="flex flex-row">
          <div className="mr-4">
            <button
              type="button"
              className="text-black border border-gray-300 bg-slate-50 hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1 font-semibold"
              onClick={handleExcel}
            >
              <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 8H1.5V8.5V9.5C1.5 9.89783 1.65804 10.2794 1.93934 10.5607C2.22064 10.842 2.60217 11 3 11H7H7.5V10.5V8.5V8H7H2ZM8.5 10.5V11H9H13C13.3978 11 13.7794 10.842 14.0607 10.5607C14.342 10.2794 14.5 9.89783 14.5 9.5V8.5V8H14H9H8.5V8.5V10.5ZM8.5 6.5V7H9H14H14.5V6.5V4.5V4H14H9H8.5V4.5V6.5ZM7.5 4.5V4H7H2H1.5V4.5V6.5V7H2H7H7.5V6.5V4.5ZM1.23223 1.73223C1.70107 1.26339 2.33696 1 3 1H13C13.663 1 14.2989 1.26339 14.7678 1.73223C15.2366 2.20107 15.5 2.83696 15.5 3.5V9.5C15.5 10.163 15.2366 10.7989 14.7678 11.2678C14.2989 11.7366 13.663 12 13 12H3C2.33696 12 1.70107 11.7366 1.23223 11.2678C0.763392 10.7989 0.5 10.163 0.5 9.5V3.5C0.5 2.83696 0.763392 2.20107 1.23223 1.73223Z" stroke="#374151" />
              </svg>
              Excel
            </button>
          </div>
          <div className="mr-4">
            <button
              type="button"
              className="text-white border border-gray-300 bg-slate-400 hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1"
              onClick={handlePring}
            >
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.1667 18.3337H5.83342C5.74501 18.3337 5.66022 18.2985 5.59771 18.236C5.5352 18.1735 5.50008 18.0887 5.50008 18.0003V14.3337H14.5001V18.0003C14.5001 18.0887 14.465 18.1735 14.4025 18.236C14.3399 18.2985 14.2552 18.3337 14.1667 18.3337ZM3.33341 8.50033H16.6667C16.9762 8.50033 17.2729 8.62324 17.4917 8.84203C17.7105 9.06083 17.8334 9.35757 17.8334 9.66699V14.667C17.8334 14.9764 17.7105 15.2732 17.4917 15.492C17.3964 15.5873 17.2863 15.6644 17.1667 15.7211V13.8337C17.1667 13.259 16.9385 12.7079 16.5321 12.3016C16.1258 11.8953 15.5747 11.667 15.0001 11.667H5.00008C4.42545 11.667 3.87435 11.8953 3.46802 12.3016C3.06169 12.7079 2.83341 13.259 2.83341 13.8337V15.7211C2.7139 15.6644 2.60378 15.5873 2.50846 15.492C2.28966 15.2732 2.16675 14.9764 2.16675 14.667V9.66699C2.16675 9.35757 2.28966 9.06083 2.50846 8.84203C2.72725 8.62324 3.024 8.50033 3.33341 8.50033ZM15.3334 3.83366V5.83366H4.66675V3.83366C4.66675 3.52424 4.78966 3.22749 5.00846 3.0087C5.22725 2.78991 5.524 2.66699 5.83342 2.66699H14.1667C14.4762 2.66699 14.7729 2.78991 14.9917 3.0087C15.2105 3.22749 15.3334 3.52424 15.3334 3.83366Z" fill="white" stroke="white" />
              </svg>
              Print
            </button>
          </div>
          <div>
            <Link to={selectedDevice !== "" ? `/device-logs/report/${selectedDevice}?from=${selectedDeviceStartDate}&to=${selectedDeviceEndDate}` : ""}>
              <button
                type="button"
                className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-md w-full px-3 py-2.5 text-center flex items-center gap-1"
              >
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1389_4052)">
                    <path d="M11.5607 11.5607C11.2794 11.842 10.8978 12 10.5 12C10.1022 12 9.72064 11.842 9.43934 11.5607C9.15804 11.2794 9 10.8978 9 10.5C9 10.1022 9.15804 9.72064 9.43934 9.43934C9.72064 9.15804 10.1022 9 10.5 9C10.8978 9 11.2794 9.15804 11.5607 9.43934C11.842 9.72064 12 10.1022 12 10.5C12 10.8978 11.842 11.2794 11.5607 11.5607Z" fill="white" stroke="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.958008 10.5C2.23201 6.443 6.02201 3.5 10.5 3.5C14.978 3.5 18.768 6.443 20.042 10.5C18.768 14.557 14.978 17.5 10.5 17.5C6.02201 17.5 2.23201 14.557 0.958008 10.5ZM14.5 10.5C14.5 11.5609 14.0786 12.5783 13.3284 13.3284C12.5783 14.0786 11.5609 14.5 10.5 14.5C9.43914 14.5 8.42173 14.0786 7.67158 13.3284C6.92143 12.5783 6.50001 11.5609 6.50001 10.5C6.50001 9.43913 6.92143 8.42172 7.67158 7.67157C8.42173 6.92143 9.43914 6.5 10.5 6.5C11.5609 6.5 12.5783 6.92143 13.3284 7.67157C14.0786 8.42172 14.5 9.43913 14.5 10.5Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1389_4052">
                      <rect width="20" height="20" fill="white" transform="translate(0.5 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                View Report
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* Add Faqs End */}
      {/* Card Section Begin */}
      <div className="py-3">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          {cardData.map((card, index) => (
            <Card title={card.title} count={card.count} icon={card.icon} />
          ))}
          <div className="bg-white p-4 my-4 rounded-lg shadow flex justify-between">
            <ToggleSwitch initialState={graphicalMode} onToggle={handleToggle} />
            <label htmlFor="Graph Text" className="font-medium">
              Graphical Mode
            </label>
          </div>
        </div>
      </div>
      {/* Card Section End */}
      <div className="bg-white mt-4 w-full p-2 flex-col rounded-lg">
        <Formik
          enableReinitialize={true}
          initialValues={{
            device: selectedDevice,
            from_date: selectedDeviceStartDate,
            to_date: selectedDeviceEndDate,
          }}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, resetForm }) => (
            <Form>
              <div className="flex-1">
                <div className="w-full">
                  <div className="grid gap-4 md:grid-cols-4">
                    <Select
                      label="DEVICE"
                      name="device"
                      options={transformData()}
                      false
                      needSearch={true}
                    />
                    <InputField
                      label="FROM DATE"
                      name="from_date"
                      type="datetime-local"
                      placeholder="DD-MM-YYYY"
                      false
                    />

                    <InputField
                      label="TO DATE"
                      name="to_date"
                      type="datetime-local"
                      placeholder="DD-MM-YYYY"
                      false
                    />
                    <div className="flex flex-col h-full justify-between">
                      <div className="flex-grow">
                      </div>
                      <Button
                        type="submit"
                        className="text-white bg-primary hover:bg-primary-dark font-sm rounded-lg text-lg w-full px-8 py-1.5 text-center mb-2"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Applying..." : "Apply"}
                      </Button>
                    </div>

                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {
        selectedDevice !== "" ? <>
          {/* Table Begin */}
          < div className="mt-2">
            {graphicalMode ? <div className="bg-white shadow rounded-lg p-4">
              {/* <div className="flex">
                <div className="mr-2">
                  <CustomColumnText title="Average Temp" value="12.5°C" titleColor="#6B7280" valueSize={18} valueWeight="Bold" valueColor="#000000" />
                </div>
                <CustomColumnText title="Average Hum" value="85%" titleColor="#6B7280" valueSize={18} valueWeight="Bold" valueColor="#000000" />
              </div> */}
              {/* <DynamicMultiLineChart data={deviceLogData?.results} /> */}
              {
                isLoadingGraph ? <></> :
                  <RenderPythonGraph deviceId={selectedDevice} endDateTime={selectedDeviceEndDate} startDateTime={selectedDeviceStartDate} />
              }
            </div> : <Table
              headers={isNewData ? headers : headers2}
              keys={isNewData ? keys : keys2}
              rows={deviceLogData?.results}
            />}
          </div>
          {/* Table End */}
          {/* Pagination Begin */}
          {
            graphicalMode ?
              <></>
              :
              <div className="xl:pl-5 pl-0">
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(deviceLogData?.count / pageSize)}
                  pageSize={pageSize}
                  totalEntries={deviceLogData?.count}
                  onPageChange={handlePageChange}
                  data={deviceLogData}
                  setData={setDeviceLogData}
                />
              </div>
          }
          {/* Pagination End */}
        </> : <div className="flex justify-center items-center h-32">
          <StyledHeadingText text={"Please select a device to view logs"} />
        </div>
      }
    </div >
  );
};

export default DeviceLogs;
